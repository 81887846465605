<template>
  <div id="app">
    <Traffic/>
  </div>
</template>

<script>
import Traffic from './components/Traffic'

export default {
  name: 'App',
  components: {
    Traffic
  }
}
</script>

<style>
  #app {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px;
  }

  *{
    margin: 0;
    padding: 0;
  }
</style>
