<template>
  <div class="content">
    <div class="trans">
        <transition-group appear>
            <div v-show="isShow.red" :key="1" class="title posabs">红灯停</div>
            <div v-show="isShow.green" :key="2" class="title posabs">绿灯行</div>
            <div v-show="isShow.yellow" :key="3" class="title posabs">黄灯等一等</div>
        </transition-group>
    </div>
    <div class="traffic p20">
        <div :class="lightRed"></div>
        <div :class="lightGreen"></div>
        <div :class="lightYellow"></div>
        <transition>
            <div class="text">{{colorName}}灯剩余{{time | timeDisplay}}秒</div>
        </transition>
    </div>
    <div class="bottom p10">
        <select v-model="color">
            <option value="red">red</option>
            <option value="green">green</option>
            <option value="yellow">yellow</option>
        </select>

        <select v-model="bindDuration">
            <option value="5">5s</option>
            <option value="10">10s</option>
            <option value="15">15s</option>
        </select>

        <button @click="change(false)">重新开始</button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Traffic',
    data() {
        return {
            signal: {
                red: {
                    next: 'green',
                },
                yellow: {
                    next: 'red',
                },
                green: {
                    next: 'yellow',
                }
            },
            current: 'yellow',
            color: 'yellow',
            time: 5,
            bindDuration: 5,
            isShow: {
                red: false,
                green: false,
                yellow: true
            },
        }
    },
    computed:{
        colorName(){
            let cname = ''
            if (this.current === 'red') {
                cname = '红'
            } else if(this.current === 'green') {
                cname = '绿'
            } else{
                cname = '黄'
            }
            return cname
        },
        lightRed(){
            return this.current === 'red' ? 'light red' : 'light'
        },
        lightGreen(){
            return this.current === 'green' ? 'light green' : 'light'
        },
        lightYellow(){
            return this.current === 'yellow' ? 'light yellow' : 'light'
        },
    },
    filters: {
        timeDisplay(value){
            return value < 10 ? '0' + value : value
        }
    },
    methods: {
        startTimer(){
            for (const key in this.isShow) {
                this.isShow[key] = false
            }
            this.isShow[this.current] = true
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                if (--this.time <= 0) {
                    this.change(true)
                }
            }, 1000);
        },
        
        change(isAuto){
            this.time = this.bindDuration
            if (isAuto) {
                this.current = this.signal[this.current].next
            } else {
                this.current = this.color
            }
            this.startTimer()
        }
    },
    mounted() {
        //this.isShow.yellow = true
        this.startTimer()
    },
}
</script>

<style scoped>
    .title{
        background-color: aqua;
        text-align: center;
    }
    
    .content{
        width: calc(100% / 3);
        min-width: 240px;
        overflow: hidden;
    }

    .trans{
        position: relative;
        height: 50px;
    }

    .posabs{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        line-height: 50px;
    }

    .traffic{
        display: flex;
        justify-content: space-evenly;
        
        background-color: rgb(205, 231, 160);
    }

    .bottom{
        display: flex;
        justify-content: space-evenly;
        
        background-color: rgb(100, 156, 5);
    }
    .p10{
        padding: 10px;
    }

    .p20{
        padding: 20px 0;
    }
    .light{
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: gray;
    }

    .text{
        height: 30px;
        line-height: 30px;
    }

    .red{
        background-color: red;
    }
    .green{
        background-color: rgb(6, 245, 6);
    }
    .yellow{
        background-color: yellow;
    }


    .v-enter-active,
    .v-leave-active {
        transition: all 0.8s linear;
    }

    /* 进入：始位置 */
    .v-enter {
        transform: translateX(100%);
        opacity: 0.5;
    }
    /* 进入：末位置 */
    .v-enter-to {
        transform: translateX(0);
        opacity: 1;
    }

    /* 离开：始位置 */
    .v-leave {
        transform: translateY(0);
        opacity: 1;
    }
    /* 离开：末位置 */
    .v-leave-to {
        transform: translateY(300%);
        opacity: 0;
    }

</style>>

</style>